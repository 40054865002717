import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import {RestService} from '../../api/rest.service';
import {CommonService} from '../../services/common.service';
import {NavController} from "@ionic/angular";
import {InviteService} from "../../services/invite.service";

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {

  constructor(private common: CommonService,
              private navCtrl: NavController,
              private inviteService: InviteService,
              private rest: RestService,
              private route: ActivatedRoute,
              private router: Router,
              private storage: Storage,
              ) {

    this.route.queryParams.subscribe((params) => {
      console.log(['queryParams', params]);

      // a : 광고해쉬, b : 블록코드, c : 사용자해쉬, d : 공유타입 (BLOCK_TYPE05 : 공유, BLOCK_TYPE06 : 초대)
      if (params.d === 'BLOCK_TYPE06') {
        console.log(['BLOCK_TYPE06']);

        this.storage.get(this.common.inviteKey).then((res) => {
          console.log(['storage invite  : ', res]);
        });

        // 로그인 여부 - 이미 로그인 되있는 사용자는 초대 블록 생성안됨
        storage.get(common.authKey).then( (res) => {
          console.log(['const storage', res]);
          if (res) {
            console.log(['res join_type', res[0].join_type]);
            const navigationExtras: NavigationExtras = {
              state: {
                data: {
                  id: params.a,
                },
              },
            }
            this.navCtrl.navigateForward('detail', navigationExtras);
            // this.router.navigate(['ct', 'detail'], navigationExtras);
          } else {
            console.log(['not login set storage']);
            // 저장소 저장
            const data = {
              user_code : params.c,
              event_hash : params.a,
              block_hash : params.b,
            };

            // this.storage.set(this.common.inviteKey, data);
            this.inviteService.setInvite(data);
            // 회원가입 페이지로 이동 시키자
            // this.router.navigateByUrl('/public/signupAgree');
            this.navCtrl.navigateForward('register-invite');
          }
        });
        // 저장소에 기록한다.
      } else {
        console.log(['BLOCK_TYPE05']);
        // 로그인 여부 - 이미 로그인 되있는 사용자는 초대 블록 생성안됨
        storage.get(common.deviceKey).then( (res) => {
          console.log(['const storage', res]);
          if (res) {
            console.log(['res', res]);
            const userCode = res.user_code;

            // if (params.b == '33cb8496449277176e2aff73b340d38f') {
            //
            // }

            const eventHash = params.a;
            const param = {
              event_hash: eventHash
            }
            this.rest.getDetail(param).then((res2) => {
              console.log(['getDetail Event code', res2]);

              // 찾기완료
              const data = {
                user_code : params.c,
                event_hash : params.a,
                block_hash : params.b,
                share_user : userCode,
                event_code : res2[0].ads[0].event_code
              };
              this.common.findBlock(data);
            });

            const link = 'https://bit.ly/2VPu5c2';

            if (link) {
              window.open(link, '_system');
            }



            // const navigationExtras: NavigationExtras = {
            //   state: {
            //     data: {
            //       id: params.a,
            //     },
            //   },
            // }
            // this.navCtrl.navigateForward('detail', navigationExtras);
            // this.router.navigate(['ct', 'detail'], navigationExtras);

          } else {
            console.log(['res fail', res]);

            const eventHash = params.a;
            const param = {
              event_hash: eventHash
            }
            this.rest.getDetail(param).then((res2) => {
              console.log(['getDetail Event code', res2]);

              // 찾기완료
              const data = {
                user_code : params.c,
                event_hash : params.a,
                block_hash : params.b,
                share_user : '',
                event_code : res2[0].ads[0].event_code
              };
              this.common.findBlock(data);
            });
            
          }
        });
      }
    });
  }

  ngOnInit() {}

}
