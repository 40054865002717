import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertController, ModalController, NavParams, PopoverController, ToastController} from '@ionic/angular';
import {VideoAdComponent} from '../components/video-ad/video-ad.component';
import {VideoInfoPage} from '../video-info/video-info.page';
import {forEach} from '@angular-devkit/schematics';
import {isArray} from 'util';
import {timeout} from "rxjs/operators";
import {UserService} from "../services/user.service";
import {CommonService} from "../services/common.service";
// import {YTPlayerConfig} from 'angular-youtube-player';

@Component({
  selector: 'app-video-test',
  templateUrl: './video-test.page.html',
  styleUrls: ['./video-test.page.scss'],
})
export class VideoTestPage implements OnInit, OnDestroy {
  YT: any;
  video: any;
  player: any;
  reframed = false;
  list = [
    {time: 4000, reminder: 4000},
    {time: 8000, reminder: 8000},
    {time: 12000, reminder: 12000}
  ];

  timer: any;
  timerId: any;
  start = 0;
  goalTime: any;
  remining = 0;
  playingTIme = 0;
  user : any;
  modalTitle = 'Youtube ';

  constructor(private alertCtrl: AlertController,
              private common: CommonService,
              private modalCtrl: ModalController,
              public popoverCtrl: PopoverController,
              public toastCtrl: ToastController,
              private navParams: NavParams,
              private userService: UserService) {
    console.log(['navParams'
      , this.navParams.data
      , this.navParams.data.youtube_id.substring(this.navParams.data.youtube_id.lastIndexOf('/')+1)
      , this.userService.getUser()]);

    this.video = this.navParams.data.youtube_id.substring(this.navParams.data.youtube_id.lastIndexOf('/')+1)
    this.modalTitle = this.navParams.data.level_explain;
    this.goalTime = this.navParams.data.goal_sec * 1000;
    this.user = this.userService.getUser()
    const tag = document.createElement('script');
    tag.setAttribute('id', 'youtube_script')
    tag.setAttribute('allowfullscreen', '');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  ngOnInit() {
    console.log(['ngOnInit']);


    // this.video = 'M7lc1UVf-VE';
    // this.video = 'GhvgBf_Mkco';
  }

  ngOnDestroy(): void {
    const firstScriptTag = document.getElementById('youtube_script');
    if (firstScriptTag) firstScriptTag.remove();

    console.log(['ngOnDestroy', firstScriptTag]);
  }

  ionViewWillEnter() {
    console.log(['app component ionViewWillEnter']);
    // @ts-ignore
    window['onYouTubeIframeAPIReady'] = (e) => {
      console.log(['onYouTubeIframeAPIReady']);
      this.YT = window['YT'];
      this.reframed = false;
      this.player = new window['YT'].Player('player', {
        width: '100%',
        videoId: this.video,
        events: {
          'onStateChange': this.onPlayerStateChange.bind(this),
          'onError': this.onPlayerError.bind(this),
          'onReady': (e) => {
            if (!this.reframed) {
              this.reframed = true;
              // reframe(e.target.a);
            }
          }
        }
      });
    };

    this.YT = window['YT'];
    this.reframed = false;
    this.player = new window['YT'].Player('player', {
      width: '100%',
      videoId: this.video,
      events: {
        'onStateChange': this.onPlayerStateChange.bind(this),
        'onError': this.onPlayerError.bind(this),
        'onReady': (e) => {
          if (!this.reframed) {
            this.reframed = true;
            // reframe(e.target.a);
          }
        }
      }
    });
  }

  async onPlayerStateChange(event) {
    console.log(['onPlayerStateChange', event]);

    // const Timer = function(callback, delay) {
    //   let timerId, start, remaining = delay;
    //
    //   console.log(['timer fn', remaining]);
    //
    //   this.pause = function () {
    //     window.clearTimeout(timerId);
    //     remaining -= Date.now() - start;
    //   }
    //
    //   this.resume = function () {
    //     start = Date.now();
    //     window.clearTimeout(timerId);
    //     timerId = window.setTimeout(callback, remaining);
    //     console.log();
    //   }
    //
    //   console.log(['resume', this.resume]);
    //
    //   // this.resume();
    // }



    let tId: any;
    switch (event.data) {
      case window['YT'].PlayerState.PLAYING:
          console.log(['currentTime', this.player.getCurrentTime(),  Date.now() - this.goalTime]);
        this.start = Date.now();
        if (this.cleanTime() === 0) {
          // this.remining = 5000;
          console.log('started ' + this.cleanTime());


          // 시작
          this.timer = setTimeout(this.findBlock, this.goalTime, this.user, this.navParams.data, this.common);

        } else {
          // this.remining = 5000;
          console.log('playing ' + this.cleanTime(), this.remining);
          if (this.remining > 0) {
            this.timer = setTimeout(this.findBlock, this.remining, this.user, this.navParams.data, this.common);
          }
        }
        console.log(['remining', this.remining]);



        // const arr = [];
        // this.list.forEach((item) => {
        //   console.log(['foreach', item, item.reminder]);
          // if (item.reminder > 0) {
          // arr.push(
          //     {fn: setTimeout(() => {
          //       this.Popover();
          //     }, item.reminder), list: item}
          //   );
          // }
          // arr.push(item.time);
        // });
        //
        // if (arr.length > 0) {
        //   this.timerId = arr;
        // }
        // this.timerId = tId;
        break;
      case window['YT'].PlayerState.PAUSED:
        if (this.player.getDuration() - this.player.getCurrentTime() != 0) {
          console.log(['paused' + ' @ ' + this.cleanTime(), this.goalTime, Date.now() - this.start, this.remining]);

          this.playingTIme += Date.now() - this.start;
          console.log(['playingTIme', this.playingTIme]);

          if (this.playingTIme < this.goalTime) {
            this.remining = this.goalTime - this.playingTIme;
          }

          clearTimeout(this.timer);

          console.log(['남은 시간', this.remining]);

        }
        console.log(['pause timerId : ', tId, this.timerId]);


        // if (isArray(this.timerId)) {
        //   let i = 0;
        //   this.timerId.forEach((item) => {
        //     const currTime = this.list[item.list].time;
        //     // item.list.key
        //     clearTimeout(item.fn);
        //     console.log(['pause time ', i, currTime]);
        //     this.list[i].reminder = currTime - this.cleanTime();
        //     console.log(['pause after time ', i, this.list[i].reminder, this.list[i].time]);
        //     i++;
        //   });
        // }
        break;
      case window['YT'].PlayerState.ENDED:
        console.log('ended ');
        this.closeModal();
        break;
    }
  }

  // utility
  cleanTime() {
    return Math.round(this.player.getCurrentTime()) * 1000;
    // return this.player.getCurrentTime() * 1000;
    // return this.player.getCurrentTime();
  }

  async closeModal() {
    const alert = await this.alertCtrl.create({
      header: '미션 힌트',
      cssClass: 'hint-alert',
      // subHeader: 'Subtitle',
      message: '시청이 완료되었습니다.<br>창을 닫으시겠습니까?',
      buttons: [{text: 'ok', handler: () => {
          this.modalCtrl.dismiss();
      }}],
    });

    await alert.present();
  }

  goBack() {
    this.modalCtrl.dismiss();
  }

  async Popover() {
    const popover = await this.popoverCtrl.create({
      component: VideoInfoPage,
      translucent: true
    });
    // popover.present();

    const toast = await this.toastCtrl.create({
      message: '블록이 수집되었습니다.',
      duration: 2000
    });
    toast.present();
  }

  onPlayerError(event) {
    switch (event.data) {
      case 2:
        console.log('' + this.video)
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    };
  }

  getTime() {
    console.log(['getTime', this.player.getCurrentTime()]);
  }

  findBlock(user, navParams, common) {
    console.log(['find block', user]);
    const data = {
      // user_code : this.rest.loginUser[0].user_code,
      user_code : user.user_code,
      event_code : navParams.event_code,
      device_id: user.device_id,
      event_hash : navParams.event_hash,
      block_hash : navParams.block_hash,
      qr_code : '',
      team_check : false,
      block_type : navParams.block_type,
      score: 0,
    };
    common.findBlock(data).then((block) => {
      console.log(['findBlock res', block]);
    }, (fail) => {
      console.log(['findBlock fail', fail]);
    });
  }

}
